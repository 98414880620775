<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-25 10:31:33
-->
<template>
  <el-scrollbar ref="scrollbar">
    <el-card class="box query">
      <el-form
        :model="query"
        ref="query"
        :inline="true"
        @submit.prevent="onSearch"
      >
        <el-form-item label="单号">
          <el-input
            v-model="query.no"
            placeholder="报备单号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="公司">
          <choose-company @change="onCompanyChange"></choose-company>
        </el-form-item>
        <el-form-item label="项目">
          <choose-estate @change="onEstateChange"></choose-estate>
        </el-form-item>

        <el-form-item label="客户">
          <el-input
            v-model="query.q"
            placeholder="姓名、手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="报备人">
          <el-input
            v-model="query.consumer"
            placeholder="姓名、手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="带看人">
          <el-input
            v-model="query.daikan"
            placeholder="姓名、手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="报备日期">
          <el-date-picker
            v-model="query.dates"
            type="datetimerange"
            value-format="YYYY-MM-DD HH:mm"
            unlink-panels
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="query.status"
            placeholder
            clearable
            style="width: 150px"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in enums.report_status"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司分类">
          <el-select
            v-model="query.is_deyou"
            placeholder
            clearable
            style="width: 150px"
          >
            <el-option label="德佑" :value="true"></el-option>
            <el-option label="分销" :value="false"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="处理人">
          <el-input
            v-model="query.modify"
            placeholder="姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="客服处理">
          <el-select
            v-model="query.isAc"
            placeholder
            clearable
            style="width: 100px"
          >
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客服姓名">
          <el-input v-model="query.kf" placeholder="姓名" clearable></el-input>
        </el-form-item>

        <el-form-item label="有附件">
          <el-select
            v-model="query.isAttrs"
            placeholder
            clearable
            style="width: 100px"
          >
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            round
            @click="onSearch"
            icon="search"
            native-type="submit"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <div></div>
    </el-card>

    <el-card class="box data report_wrp report_data" v-loading="loading">
      <div style="margin-bottom: 10px; font-size: 20px">
        总共 <b>{{ tableData.counts }}</b> 条报备记录
      </div>
      <el-scrollbar v-if="tableData.counts">
        <div class="grid">
          <div
            class="item"
            v-for="(item, index) in tableData.data"
            :key="index"
          >
            <!-- <el-card shadow="hover">
            <el-descriptions :column="1">
              <el-descriptions-item label="系统编号">{{
                item.id
              }}</el-descriptions-item>
              <el-descriptions-item label="报备单号">{{
                item.hNumber
              }}</el-descriptions-item>
              <el-descriptions-item label="公司名称">{{
                item.company
              }}</el-descriptions-item>
              <el-descriptions-item label="带看项目">{{
                item.estateName
              }}</el-descriptions-item>
              <el-descriptions-item label="带看人"
                >{{ item.daikanName }}/{{
                  item.daikanMobile
                }}</el-descriptions-item
              >
              <el-descriptions-item label="报备人"
                >{{ item.consumer_Name }}/{{
                  item.consumer_Mobile
                }}</el-descriptions-item
              >
              <el-descriptions-item label="客户姓名">{{
                item.clientName
              }}</el-descriptions-item>
              <el-descriptions-item label="客户性别">{{
                item.sex
              }}</el-descriptions-item>
              <el-descriptions-item label="客户手机">{{
                item.clientMobile
              }}</el-descriptions-item>
              <el-descriptions-item label="到案人数">{{
                item.dueNum
              }}</el-descriptions-item>
              <el-descriptions-item label="到案时间">{{
                item.dueTime
              }}</el-descriptions-item>
              <el-descriptions-item label="报备备注">{{
                item.comment
              }}</el-descriptions-item>
              <el-descriptions-item label="报备时间">{{
                item.creationTime
              }}</el-descriptions-item>
              <el-descriptions-item label="状态">
                <el-link
                  type="warning"
                  :underline="false"
                  v-if="item.curStatus == 0"
                  >{{ item.cur_status_text }}</el-link
                >
                <el-link
                  type="primary"
                  :underline="false"
                  v-else-if="item.curStatus == 1"
                  >{{ item.cur_status_text
                  }}{{ item.unknown ? "(结果未知)" : "" }}</el-link
                >
                <el-link
                  type="success"
                  :underline="false"
                  v-else-if="item.curStatus == 2"
                  >{{ item.cur_status_text }}</el-link
                >
                <el-link
                  type="danger"
                  :underline="false"
                  v-else-if="item.curStatus == 3"
                  >{{ item.cur_status_text }}</el-link
                >
                <el-link :underline="false" v-else>{{
                  item.cur_status_text
                }}</el-link>
              </el-descriptions-item>
              <el-descriptions-item label="处理人"
                >{{ item.modify_name }} {{ item.modifiedTime }}

                <span v-if="item.isAcData"
                  >{{ item.kefu_name }}({{ item.kefu_mobile }})</span
                >
              </el-descriptions-item>
              <el-descriptions-item label="处理结果">{{
                item.resultContent
              }}</el-descriptions-item>
            </el-descriptions>
          </el-card> -->
            <el-card shadow="hover">
              <report-item
                :item="item"
                @preview-att="onPreviewAtt"
                @upload-qr-code="onUploadQrCode"
              ></report-item>
              <div class="buttons">
                <el-button
                  v-if="item.sendTime"
                  type="info"
                  size="small"
                  color="#626aef"
                  icon="upload"
                  @click="onUploadAtt(item)"
                  >上传附件</el-button
                >
              </div>
            </el-card>
          </div>
        </div>
      </el-scrollbar>
      <el-empty v-if="!loading && !tableData.counts"></el-empty>

      <el-pagination
        v-if="tableData.counts"
        @size-change="pageSizeChange"
        @current-change="pageCurrentChange"
        :current-page="query.pageIndex"
        :page-sizes="[20, 40, 60, 80, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="tableData.counts"
      ></el-pagination>
    </el-card>
  </el-scrollbar>

  <upload-qr-code
    :item="current_qr_item"
    @closed="current_qr_item = null"
    @success="loadData"
  ></upload-qr-code>
  <upload-att
    :item="current_att_item"
    @closed="current_att_item = null"
    @success="loadData"
  ></upload-att>

  <el-dialog title="查看附件(点击可预览)" v-model="visibleDialog">
    <div
      class="att_image_preview"
      v-if="preview_att_item && preview_att_item.attUrls"
    >
      <el-image
        style="width: 100px; height: 100px"
        :src="url"
        lazy
        v-for="(url, index) in preview_att_item.attUrls"
        :key="index"
        :preview-src-list="preview_att_item.attUrls"
        :initial-index="index"
        fit="cover"
      />
    </div>
  </el-dialog>
</template>

<script>
import common_api from "@/http/common_api";
import ChooseCompany from "../component/choose_company.vue";
import ChooseEstate from "../component/choose_estate.vue";
import ReportItem from "./report_item.vue";
import UploadAtt from "./att/upload_att.vue";
import UploadQrCode from "./newqrcode/upload_qrcode.vue";

export default {
  components: {
    ChooseCompany,
    ChooseEstate,
    ReportItem,
    UploadAtt,
    UploadQrCode,
  },
  data() {
    return {
      loading: false,
      visibleDialog: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      enums: {},
      tableData: {
        counts: 0,
      },
      current_qr_item: null,
      current_att_item: null,
      preview_att_item: null,
    };
  },
  created() {
    // const parentRefs = this.$parent.$refs;
    console.log(this.$parent.$refs.main);
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
    this.loadData();
  },
  methods: {
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
      this.$refs.scrollbar.setScrollTop(0); 

    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
      this.$refs.scrollbar.setScrollTop(0);
    },

    /**
     * 公司选择更改
     * @param {*} id
     */
    onCompanyChange(id) {
      this.query.companyId = id;
    },

    /**
     * 项目选择
     * @param {*} id
     */
    onEstateChange(id) {
      if (id) {
        this.query.estateIds = [id];
      } else {
        delete this.query.estateIds;
      }
    },

    /**
     *
     */
    loadData() {
      this.loading = true; 
      this.$http
        .post("/admin/v1/report", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     * 上传附件
     * @param {*} item
     */
    onUploadAtt(item) {
      this.current_att_item = item;
    },

    /**
     * 查看附件
     */
    onPreviewAtt(item) {
      this.preview_att_item = item;
      this.visibleDialog = true;
    },

    /**
     * 上传二维码
     * @param {*} item
     */
    onUploadQrCode(item) {
      this.current_qr_item = item;
    },
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 10px;
  column-gap: 10px;
}
</style>
